import React from 'react'
import Navbar from '../inc/Navbar'
import Deposit from './Deposit'
import {Link} from "react-router-dom";
function Depositpage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        
        <div className='mahilaloanmurshidabad'>
            <div className="album py-1">
                <div className="container mt-4">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Deposite & Scheme</li>
                        </ol>
                    </nav>
                </div>
                </div>
            </div>
            <br/>
        <Deposit/>
        <br/>
    </div>
  )
}

export default Depositpage
import React, { useState, useEffect } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'


function Slider() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(9);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [slider, setSlider] = useState([])
    const navigate = useNavigate()

    const sliderCollectionRef = collection(db, "HOME SLIDER");
    useEffect(() => {

        const getSlider = async () => {
            const data = await getDocs(sliderCollectionRef);
            setSlider(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getSlider()
    }, [])
    return (
        <div>

            {slider.filter((val) => {
                if (setsearchTerm === "") {
                    return val;
                } {
                    return val;
                }
            }).slice(pagination.start, pagination.end).map((mzmuccsl) => {
                return (



                    <div className="card-body">

                        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">

                                    <div className='borderr'>
                                        <img src={mzmuccsl.slider1} className="d-block w-100" alt="mzmuccsl" />
                                    </div>

                                </div>
                                <div className="carousel-item">
                                    <div className='borderr'>
                                        <img src={mzmuccsl.slider2} className="d-block w-100" alt="MURSHIDABAD ZILA MAHILA URBAN CO-OPERATIVE CR.SOC.LTD" />

                                    </div>
                                </div>

                                <div className="carousel-item">

                                    <div className='borderr'>
                                        <img src={mzmuccsl.slider3} className="d-block w-100" alt="MURSHIDABAD ZILA MAHILA URBAN CO-OPERATIVE CR.SOC.LTD" />
                                    </div>
                                </div>

                            


                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>









                    </div>

    )
})}



        </div >
    )
}

export default Slider

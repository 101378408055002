import React, { useState, useEffect }  from 'react';

import {Link, useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {db} from '../../firebase'



function Marquee () {
   const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(9);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [tagline, setTagline] = useState([])
      const navigate = useNavigate()
  
      const taglineCollectionRef = collection(db, "Right to left Slide");
      useEffect(() => {
  
          const getTagline = async () => {
              const data = await getDocs(taglineCollectionRef);
              setTagline(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getTagline()
      }, [])
  return (
    <div>

{tagline.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } {
        return val;
      }
    }).slice(pagination.start, pagination.end).map((mzmuccsl) => { return (
       
       
                       
                            <div className="card-body">
                                   
                                   <marquee>
      <center>
        <div className="textcolor">
        <h5 className='mt-2'><b>{mzmuccsl.title}</b></h5>
        </div>
        </center>
      
      </marquee>
                              
                            </div>
                           
                                )})} 
                            
                  
      
    </div>
  )
}

export default Marquee

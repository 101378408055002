import React from 'react'

function Profile() {
  return (
    <div>
      <div className="album py-3">
        <div className="container">
          <h3 style={{ color: "darkBlue" }}><b>HISTORY :</b></h3>
        </div>
      </div>
      <div className="album py-0">
        <div className="container">
          <h6>It  is the  1ST MAHILA CO-OPERATIVE CREDIT SOCIETY in Murshidabad. Located at G-5 Old Collectorate Building. It was established in 22-10-1992 as per WB Co-op. Act 1983 & Rules 1987.( Presently Which is now govern by WB Co-operative Act 2006 & Rules 2011) </h6>
          <p>It registration no. is 35MSD.22-10-1992. Our Founder Chairperson was Lt.Smt.Chaya Ghosh ( Ex- Minister & MLA of Gov. of WB   & Chief Advisor was Lt. Sri Jayanto Ray. ( Ex-MP of GOI ).</p>

          <p className='mb-3'> Initially its Objective was Savings & lending among women members</p>

          <p>Area of Operation : Seven Municipalities ie Berhampore , Beldanga , Jiagang-Azimgang, Murshidabad , Dhulian ,Jangipur ,Kandi  & semi towns like Farraka , Lalgola , Islampore , Aurangabad.   </p>

          <h5>Registration : 35MSD DT.22-10-1992 , GOV.OF WB CO-OPERATION DIRECTORATE ,MSD.RANGE.</h5>
          <h5 className='mb-5'>Member & Affiliated Society of Msd.DCCB , IFSC –WBSC0MCCB01</h5>
          <div className='loanmurshidabad'>
<center><h1><b>Why us?</b></h1></center>
<p>1.	<b>Personalized Service: </b> we also believe in the power of personal
connections. Our friendly and knowledgeable staff is always ready to assist you with expert advice  helping you make informed decisions about your finances
</p>
<p>2. <b>Security:</b> Your security is our top priority. We employ robust security measures to protect your money , information, data. Our Motto/Tag line “ Your Money Our Responsibility” </p>
<p>3. <b>Community Focus:</b> We support local initiatives, promote financial literacy, and contribute to the growth and prosperity </p>
<p className='mb-4'>4. <b>Diverse Offerings:</b> Diverse offers for our members as per need. </p>
<h6><b>Our Vales & Believes:</b>Financial Responsibilities, Best Services, Transparency , Customer Satisfaction , Adaptability & Growth.   </h6>
</div>
        </div>
      </div>
    </div>
  )
}

export default Profile

import './App.css';
import React, { useEffect} from "react";
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';



import Home from "./components/pages/Home";
import Footer from "./components/inc/Footer";
import Contactus from './components/pages/Contactus';
import Depositpage from './components/pages/Depositpage';
import Profilepage from './components/pages/Profilepage';
import LoanAplication from './components/pages/LoanAplication';
import VechileLoan from './components/pages/VechileLoan';
import Group from './components/pages/Group';
import PersonalLoan from './components/pages/PersonalLoan';
import PropertyLoan from './components/pages/PropertyLoan';
import MicrocashLoan from './components/pages/MicrocashLoan';
import BusinessLoan from './components/pages/BusinessLoan';
import GalleryActivity from './components/pages/GalleryActivity';
import Careerpage from './components/pages/Careerpage';
import Downlaod from './components/pages/Downlaod';

function App() {
  return (
  <Router>
    
  <div>
  
    <Routes>
    <Route axact path="/" element={<Home/>}/>
    <Route axact path="/contact-us" element={<Contactus/>}/>
    <Route axact path="/deposit-scheme" element={<Depositpage/>}/>
    <Route axact path="/profile" element={<Profilepage/>}/>
    <Route axact path="/loan-apply" element={<LoanAplication/>}/>
    <Route axact path="/Vechile-Loan" element={<VechileLoan/>}/>
    <Route axact path="/group" element={<Group/>}/>
    <Route axact path="/personal-salary-earner-loan" element={<PersonalLoan/>}/>
    <Route axact path="/property-mortagage-loan" element={<PropertyLoan/>}/>
    <Route axact path="/micro-cash-credit-overdraft-loan" element={<MicrocashLoan/>}/>
    <Route axact path="/Retail-Business-loan" element={<BusinessLoan/>}/>
    <Route axact path="/gallery" element={<GalleryActivity/>}/>
    <Route axact path="/career" element={<Careerpage/>}/>
    <Route axact path="/download" element={<Downlaod/>}/>
    </Routes>
    <Footer/>
  
    </div>
   
    </Router>
  );
}

export default App;





import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyBjM1C-n94TM8wlPmmPML92Mt5n9QFQDfM",
  authDomain: "murshidabad-zilamahilaurban.firebaseapp.com",
  projectId: "murshidabad-zilamahilaurban",
  storageBucket: "murshidabad-zilamahilaurban.firebasestorage.app",
  messagingSenderId: "728251541269",
  appId: "1:728251541269:web:8eabfbc77ffb107fe3ab1c",
  measurementId: "G-CB47QRLL8N"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;




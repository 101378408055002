import React from 'react'
import Navbar from '../inc/Navbar'
import AddApplyloan from '../AddApplyloan'
import {Link} from "react-router-dom";

function LoanAplication() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
      
        <div className='mahilaloanmurshidabad'>
            <div className="album py-1">
                <div className="container mt-4">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Apply Loan</li>
                        </ol>
                    </nav>
                </div>
                </div>
            </div>
        <div className='backc'>
        <div className="album py-3">
        <div className="container">
          <center>
            <h3><b>Loan Application</b></h3>
          <h6>Enter your Details for identity verification and loan processing.</h6>

          </center>
          </div>
          </div>
        <AddApplyloan/>
        <br/>
        </div>
       
    </div>
  )
}

export default LoanAplication
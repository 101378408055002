import React from 'react'
import Navbar from '../inc/Navbar'
import Slider from '../inc/Slider'
import ScrollToTop from "react-scroll-to-top";
import ReactWhatsapp from "react-whatsapp";
import "./Home.css";
import Marquee from './Marquee';
import Deposit from './Deposit';
import VECHILEloan from "../images/VECHILE loan.jpg";
import Personalsalaryearningloan from "../images/personal Salary Earning loan.jpg";
import Propertylaon from "../images/PROPERTY( LAP), MORTAGAGE LOAN.jpg";
import Microoverdraft from "../images/MICRO- CASH – CREDIT OVERDRAFT.jpg";
import RetailBusinessloan from "../images/RETAIL BUSINESS LOAN.jpg";
import {Link} from "react-router-dom";

function Home() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <ScrollToTop smooth top="100" color="Darkblue" />
      <div className="murshidabadmahilaurban">
        <div className="album py-3">
          <div className="container">
            <div className="row ">
              <div className="col-md-12" >

                <center>
                  <div className="sericulturalberhampore">
                    <h2 style={{ color: "white" }} className='mb-1 mt-3'>MURSHIDABAD ZILA MAHILA URBAN CO-OPERATIVE CR.SOC.LTD</h2>
                  </div>
                  <p style={{ color: "white" }} className='mb-1'>(REG.NO. 35 MSD  DT.22-10-1992 , CO-OPERATION DIRECTORATE , GOV.OF WB ,  MSD.RANGE ) </p>

                  <h6 style={{ color: "white" }}>REG. ADDRESS : G-5 OLD COLLECTORATE BUILDING , BERHAMPORE ,MURSHIDABAD .WB                           IFSC CODE : WBSC0MCCB01</h6>
                  <h6 style={{ color: "white" }}>YOUR MONEY OUR RESPONSIBILITY </h6>
                </center>

              </div>

              <div className="d-flex justify-content-between align-items-center">

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="album py-1">
      <div className="container">
        <center>
      <div className='btn-grad33'>8 Hours  FINANCIAL ( BANKING) SERVICES  FROM 9:15AM TO 5:45PM</div>
      </center>
      </div>
      </div>
      <div className="album py-2">
        <div className="container">

          <div className="row">
            <div className="col-md-5 mt-3">
              <div className="card text-dark bg-light mb-3" >
                <div className='btn-grad41 mb-2'>IMPORTANT LINK</div>
                <div className="card-body">
                  <div className='btn-grad42 mb-2'>LOAN & CREDIT SCHEMES </div>
                  <Link to="/gallery">
                  <div className='btn-grad42 mb-2'>SOCIAL ACTIVITIES & GALLARY </div></Link>
                  <Link to="/deposit-scheme">
                  <div className='btn-grad42 mb-2'>DEPOSIT & SCHEME</div></Link>
                  <div className='btn-grad42 mb-2'>DIGITAL MODE  </div>
                  <Link to="/group">
                  <div className='btn-grad42 mb-2'>GROUP</div></Link>
                </div>
              </div>
            </div>
            <div className="col-md-7 mt-3">
              <Slider />
            </div>
          </div>

        </div>
      </div>

      <div className="homecolornew">

        <Marquee />

      </div>
      <br />
      <div className="album py-3">
        <div className="container">
          <div className='loanmurshidabad'>
            <center><h1 className='mb-3'><b>Why us?</b></h1></center>
            <p>1.	<b>Personalized Service: </b> we also believe in the power of personal
              connections. Our friendly and knowledgeable staff is always ready to assist you with expert advice  helping you make informed decisions about your finances
            </p>
            <p>2. <b>Security:</b> Your security is our top priority. We employ robust security measures to protect your money , information, data. Our Motto/Tag line “ Your Money Our Responsibility” </p>
            <p>3. <b>Community Focus:</b> We support local initiatives, promote financial literacy, and contribute to the growth and prosperity </p>
            <p className='mb-4'>4. <b>Diverse Offerings:</b> Diverse offers for our members as per need. </p>
            <h6 className='mb-3'><b>Our Vales & Believes:</b>Financial Responsibilities, Best Services, Transparency , Customer Satisfaction , Adaptability & Growth.   </h6>
          </div>
        </div>
      </div>

      <div className="album py-3">
        <div className="container">

          <div className="row">
            <div className="col-md-4 mt-3">
              <div className="card" >
                <div className='hoverimage'>
                  <img src={VECHILEloan} className="img-fluid rounded-start image1" alt="VECHILEloan" />
                  <div className="overlay"> VECHILE LOAN <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg></div>
                </div>
                <div className="card-body">
                  <div className='vechileloan'>
                    <h5 className="card-title">VECHILE LOAN ( Three wheeler & Two Wheeler )</h5>
                  </div>
                  <div className='ptextcolor'>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill " viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 1.	ELIGIBILITY : AGE 20-58 YRS.</p>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 2.	MEMBER OF THIS  CO-OPERATIVE  </p>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 3.	Loan to Value :70%-75% of Vehicle .Down-Payment 30% of On Road Price. </p>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 4.	Tenure = 18-20 Months.</p>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 5.	Documents : AADHAR/VOTER CARD  , PAN CARD.</p>
                    <center><p style={{color:"grey"}}>  ( Processing fees - 0.75-1.5% per Cases )</p></center>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-0">

                    <a href="loan-apply" className="womenloanmurshidabad">Apply Loan</a>
                    <a href="tel: 9641435329" className="callbutton">Call Now</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="card">
                <div className='hoverimage'>
                  <img src={Personalsalaryearningloan} className="img-fluid rounded-start image1" alt="Personalsalaryearningloan"/>
                  <div className="overlay"> PERSONAL / SALARY EARNER LOAN <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg></div>
                </div>
                <div className="card-body">
                  <div className='vechileloan'>

                    <h5 className="card-title">PERSONAL / SALARY EARNER LOAN </h5>
                  </div>
                  <div className='ptextcolor'>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill " viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 1.	ELIGIBILITY: AGE 20-58 YRS.</p>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 	2. MEMBER OF THIS  CO-OPERATIVE   </p>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 3.	LOAN TO VALUE : MAXIMUM 1 LAKH IN FIRST INSTANCE , 2.5 LAKH MAXIMUM( Co-lateral Security ) </p>
                    <p className="card-text mb-3"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 4.	TENURE 24-30 MONTHS  </p>
                    <center><p style={{color:"grey"}}>  ( Processing fees - 0.75-1.5% per Cases )</p></center>
                   
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-0">

                    <a href="loan-apply" className="womenloanmurshidabad">Apply Loan</a>
                    <a href="tel: 9641435329" className="callbutton">Call Now</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="card" >
                <div className='hoverimage'>
                  <img src={Propertylaon} className="img-fluid rounded-start image1" alt="Propertylaon" />
                  <div className="overlay">LOAN AGAINST PROPERTY( LAP)  /MORTAGAGE LOAN <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg></div>
                </div>
                <div className="card-body">
                  <div className='vechileloan'>

                    <h5 className="card-title">LOAN AGAINST PROPERTY( LAP)  /MORTAGAGE LOAN </h5>
                  </div>
                  <div className='ptextcolor'>
                  <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill " viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 1.	ELIGIBILITY :- Age 20-55 yrs</p>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 2.	MEMBER THIS CO-OPERATIVE  </p>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 3. LOAN TO VALUE : 60%-65% OR VALUTION OF ENPANNLLED VALUER </p>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 4. Tenure -Decision Of Management   </p>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 5. Documents : Property ownership legal documents ie PORCHA , Sale Deed.  </p>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 6. Source of Income : Salary Earner , Business   </p>
                  </div>
                  <center><p style={{color:"grey"}}>  ( Processing fees - 0.75-1.5% per Cases )</p></center>
                  <div className="d-flex justify-content-between align-items-center mb-0">

                    <a href="loan-apply" className="womenloanmurshidabad">Apply Loan</a>
                    <a href="tel: 9641435329" className="callbutton">Call Now</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-3">
              <div className="card" >
                <div className='hoverimage'>
                  <img src={Microoverdraft} className="img-fluid rounded-start image1" alt="Microoverdraft"/>
                  <div className="overlay">MICRO-  CASH – CREDIT/OVERDRAFT  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg></div>
                </div>
                <div className="card-body">
                  <div className='vechileloan'>

                    <h5 className="card-title"> MICRO-  CASH – CREDIT/OVERDRAFT                               </h5>
                  </div>
                  <div className='ptextcolor'>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill " viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 1. Own Contribution / Owner Contribution  = 30%-40% </p>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 	2. Permissible Finance = 60%-70%   </p>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 3.	Business- Proof : Trade license , Business License , Business Vintage  Proof – 3Yrs. </p>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 4.	Minimum Bank Balance 20,000, Business income proof  : ITR . </p>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 5.	REGULAR STOCK AUDIT , PDC / CREDIT MANDATE    </p>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 6.	Age of Owner / Beneficial owner – 25-58Yrs. </p>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 7.	 Basic KYC of Owner .Aadhaar / Voter, PAN Card    </p>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>  8.	Renewal after every 1 ( one ) year.   </p>
                    
                    <center><p style={{color:"grey"}}>  ( Processing fees - 0.75-1.5% per Cases )</p></center>
                   
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-0">

                    <a href="loan-apply" className="womenloanmurshidabad">Apply Loan</a>
                    <a href="tel: 9641435329" className="callbutton">Call Now</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="card" >
                <div className='hoverimage'>
                  <img src={RetailBusinessloan} className="img-fluid rounded-start image1" alt="RetailBusinessloan"/>
                  <div className="overlay">  RETAIL BUSINESS LOAN ( UP TO RS.50,000)  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg></div>
                </div>
                <div className="card-body">
                  <div className='vechileloan'>

                    <h5 className="card-title">  RETAIL BUSINESS LOAN ( UP TO RS.50,000)                              </h5>
                  </div>
                  <div className='ptextcolor'>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill " viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 1.	Eligibility :  Business entity </p>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 	2.	PURPOSE : RETAIL WORKING CAPITAL    </p>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 3.	Check of CIBIL score </p>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 4. Documentation : Business ownership proof ie Trade license , other license as per business  etc </p>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 5.	Minimum  Bank Balance =Rs.15,000 ,Income proof : ITR, etc   </p>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>6.	Bank Statement up to 12 months  </p>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 7.	Mortgage of Stock / Stock audit etc . </p>
                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>  8.	ACCOUNT PAYEE CHEQUEE (PDC )   </p>
                    <center><p style={{color:"grey"}}>  ( Processing fees - 0.75-1.5% per Cases )</p></center>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-0">

                    <a href="loan-apply" className="womenloanmurshidabad">Apply Loan</a>
                    <a href="tel: 9641435329" className="callbutton">Call Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Deposit />
      <br />



      <div className="album py-3">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mt-3">
              <div className="card text-dark bg-light mb-3" >
                <div className='btn-grad41 mb-2'>Membership</div>
                <div className="card-body">
                  <p className='mb-2'>1.	“A CLASS” : Applicable to Women of age 18 yrs . & above , Membership fees = Rs.300( One Time ) . Must be resident of Murshidabad . To avail all the benefits one must be member. </p>
                  <p className='mb-2'>2.	GROUP MEMBER  : As per Bay-laws </p>
                  <p className='mb-3'>3.	NOMINAL MEMBER </p>
                  <a href='tel: 9641435329'>
                    <p className='mb-2'>For details : +91 9641435329</p>
                  </a>
                  <ReactWhatsapp number="+91   +91 9641435329" className="whatsappbutton" message="I am Interested"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                    <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                  </svg>  Whatsapp</ReactWhatsapp>
                </div>
              </div>
            </div>


            <div className="col-md-4 mt-3">
              <div className="card text-dark bg-light mb-3" >
                <div className='btn-grad41 mb-2'>Group</div>
                <div className="card-body">
                  <p>Groups accounts are opened here. </p>

                  <p className='mb-2'>এখানে গ্রুপ অ্যাকাউন্ট খোলা হয়। বিস্তারিত জানতে এখানে কল করুন <a href='tel: 9641435329'> +91 9641435329</a>      </p>

                  <a className="mb-3" href='tel: 9641435329'>For more details call here : +91 9641435329</a>

                </div>

              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}

export default Home
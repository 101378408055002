import React from 'react'
import Navbar from '../inc/Navbar';
import VECHILEloan from "../images/VECHILE loan.jpg";
import { Link } from "react-router-dom";


function VechileLoan() {
    return (
        <div>
            <Navbar />

            <br />
            <br />
            <br />
            <div className='mahilaloanmurshidabad'>
                <div className="album py-1">
                    <div className="container mt-4">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">VECHILE LOAN ( Three wheeler & Two Wheeler )</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
            <div className="album py-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div class="card mb-3" >
                                <div className="row g-0">
                                    <div className="col-md-5">
                                        <div className='hoverimage'>
                                            <img src={VECHILEloan} className="img-fluid rounded-start image1" alt="bestothopaedicdoctorkrishnanagar" />
                                            <div className="overlay">VECHILE LOAN ( Three wheeler & Two Wheeler ) <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg></div>
                                        </div>

                                    </div>
                                    <div className="col-md-7">
                                        <div className="card-body">
                                            <h4 style={{ color: "Darkblue" }} className="card-title mb-2"><b>VECHILE LOAN ( Three wheeler & Two Wheeler ) <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg></b></h4>
                                            <p style={{ color: "grey" }} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill " viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg> 1.	ELIGIBILITY : AGE 20-58 YRS.</p>

                                            <p style={{ color: "grey" }} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg> 2.	MEMBER OF THIS  CO-OPERATIVE  </p>
                                            <p style={{ color: "grey" }} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg> 3.	Loan to Value :70%-75% of Vehicle .Down-Payment 30% of On Road Price. </p>
                                            <p style={{ color: "grey" }} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg> 4.	Tenure = 18-20 Months.</p>
                                            <p style={{ color: "grey" }} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg> 5.	Documents : AADHAR/VOTER CARD  , PAN CARD.</p>

                                        </div>
                                    </div>
                                </div>
                                
                            </div>


                        </div>
                        <center>
                    <div className="d-flex justify-content-between align-items-center mb-0">

<a href="loan-apply" className="womenloanmurshidabad">Apply Loan</a>
<a href="tel: 9641435329" className="callbutton">Call Now</a>
</div>
                    </center>
                    </div>

                
                </div>
            </div>
            <br />
        </div>
    )
}

export default VechileLoan
import React, { useState } from "react";
import { Form, Alert, InputGroup, Button, Card } from "react-bootstrap";
import BookDataCareer from "../services/book.career";



const AddCareer = () => {
    const [title, setTitle] = useState("");
    const [jobcategory, setJobcategory] = useState("");
    const [presentaddress, setPresentaddress] = useState("");
    const [aadharcard, setAadharcard] = useState("");
    const [experience, setExperience] = useState("");
  


    const [mobile, setMobile] = useState("");

    const [status, setStatus] = useState("");
    const [flag, setFlag] = useState(true);
    const [massage, setMassage] = useState({ error: false, msg: "" });



    const handleSubmit = async (e) => {
        e.preventDefault();
        setMassage("");
        if (title === "" || mobile === "" || presentaddress === "" || jobcategory === "" || aadharcard === "" || experience === "" ) {
            setMassage({ error: true, msg: "All fields are mandatory!" });
            return;
        }
        const newBook = {
            title,
            mobile,

            aadharcard,
            experience,
           

            presentaddress,
            jobcategory,
            status
        }
        console.log(newBook);

        try {
            await BookDataCareer.addBooks(newBook);
            setMassage({ error: false, msg: "Submited Successfuly!" });
        } catch (err) {
            setMassage({ error: true, msg: err.massage });
        }

        setTitle("");
        setMobile("");

        setAadharcard("");

        setExperience("");
       


        setPresentaddress("");
        setJobcategory("");
    };



    return (
        <>






            {massage?.msg && (<Alert variant={massage?.error ? "danger" : "success"}
                dismissible
                onClose={() => setMassage("")}
            >
                {""}
                {massage?.msg}
            </Alert>
            )}

            <Form onSubmit={handleSubmit} >
                <div className="album py-2">
                    <div className="container">
                        <div className="row ">

                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookTitle">
                                    <InputGroup>
                                        <Form.Control type="text"
                                            placeholder="Enter your name"

                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </div>



                            <div className="col-md-6">

                                <Form.Group className="mb-3" controlId="formBookPresentaddress">

                                    <Form.Control type="text"
                                        placeholder="Present Full Address with PO, PS, Dist, PIN"
                                        value={presentaddress}
                                        onChange={(e) => setPresentaddress(e.target.value)}
                                    />

                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookMobile">

                                    <Form.Control type="text"
                                        placeholder="Contact No."
                                        value={mobile}
                                        onChange={(e) => setMobile(e.target.value)}
                                    />
                                </Form.Group>
                            </div>

                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookAadharcard">
                                    <Form.Control type="text"
                                        placeholder="Aadhar Card No."
                                        value={aadharcard}
                                        onChange={(e) => setAadharcard(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookJobcategory">


                                    <Form.Control as="textarea" row={3}
                                        placeholder="Job Category"
                                        value={jobcategory}
                                        onChange={(e) => setJobcategory(e.target.value)}
                                    />

                                </Form.Group>

                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookExperience">
                                    <Form.Control type="text"
                                        placeholder="Experience"
                                        value={experience}
                                        onChange={(e) => setExperience(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                          










                        </div>
                      <center>
                        <button className="newbutonadd" type="Submit">

                            Submit

                        </button>
                        </center>
                    </div>
                </div>

            </Form>






        </>


    )
}
export default AddCareer;